<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <label>
          <span>{{ $t('addListing.discount.title') }}</span>
        </label>
      </div>
      <div class="right--col">
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: showDiscount }"
          @click="showDiscount = true"
        >
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: !showDiscount }"
          @click="showDiscount = false"
        >
          <span style="position: relative;">
            {{ $t('general.no') }}
          </span>
        </button>
      </div>
    </div>
    <div v-show="showDiscount">
      <div class="form--group">
        <div class="left--col"></div>
        <div class="right--col">
          <div
            class="custom-control custom-radio mb-md-3 d-flex align-items-center"
            style="min-height: 39px;"
          >
            <div class="col-6 p-0">
              <input
                id="isDiscPercentageTrue"
                type="radio"
                v-model="isDiscPercentage"
                :value="true"
                class="custom-control-input"
                name="isDiscPercentage"
              />
              <label class="custom-control-label" :for="'isDiscPercentageTrue'">
                {{ $t('addListing.discount.percentage') }}
              </label>
            </div>
            <div class="col-6 col-md-3 p-0" v-show="isDiscPercentage">
              <div class="input--group">
                <cleave
                  class="basic--input discount--input"
                  type="text"
                  name="discount_percentage"
                  v-model="discountPercentage"
                  :class="validation.hasError('discountPercentage') ? 'is-invalid' : ''"
                  :options="cleaveOptionPercentage"
                />
                <div class="append--right">
                  %
                </div>
              </div>
              <span class="val-error" v-if="validation.hasError('discountPercentage')">{{
                validation.firstError('discountPercentage')
              }}</span>
            </div>
          </div>
          <div
            class="custom-control custom-radio mb-md-3 d-flex align-items-center"
            style="min-height: 39px;"
          >
            <div class="col-6 p-0">
              <input
                id="isDiscPercentageFalse"
                :value="false"
                v-model="isDiscPercentage"
                type="radio"
                class="custom-control-input"
                name="isDiscPercentage"
              />
              <label class="custom-control-label" :for="'isDiscPercentageFalse'">
                <!-- $t('addListing.isDiscPercentageFalse')-->
                {{ $t('addListing.discount.nominal') }}</label
              >
            </div>
            <div class="col-6 p-0" v-show="!isDiscPercentage">
              <div class="input--group">
                <div class="append--left">
                  S$
                </div>
                <Cleave
                  class="basic--input price--input"
                  type="text"
                  name="discount_price"
                  v-model="discountPrice"
                  :options="cleaveOptionPrice"
                />
              </div>
              <span class="val-error" v-if="validation.hasError('discountPrice')">{{
                validation.firstError('discountPrice')
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form--group" v-show="sellPrice">
        <label class="left--col"></label>
        <div class="right--col">
          <discount-preview :real-price="`${sellPrice}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import { Validator } from 'simple-vue-validator';
const DiscountPreview = () =>
  import('@/components/listing-form/sect1/discount/discount-preview.vue');

export default {
  name: 'discount-sell-section',
  components: { Cleave, DiscountPreview },
  data() {
    return {
      cleaveOptionPrice: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      cleaveOptionPercentage: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 2,
        numeralDecimalScale: 2,
      },
    };
  },
  computed: {
    sellPrice() {
      return this.$store.state.v2.listingForm.sect1.basicInfo.sellPrice;
    },
    showDiscount: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.showDiscount;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SHOW_DISCOUNT', value);
      },
    },
    isDiscPercentage: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.isDiscPercentage;
      },
      set(val) {
        this.discountPercentage = null;
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_IS_DISC_PERCENTAGE', val);
      },
    },
    discountPercentage: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.discountPercentage;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DISCOUNT_PERCENTAGE', val);
      },
    },
    selectedRentPrices() {
      return this.$store.getters['v2/listingForm/sect1/basicInfo/selectedRentPrices'];
    },
    discountPrice: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.discountPrice;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DISCOUNT_PRICE', val);
      },
    },
  },
  validators: {
    discountPrice(value) {
      if (this.isDiscPercentage === true || !this.showDiscount) return null;
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.discount.requiredN'))
        .between(
          1,
          this.sellPrice,
          this.$i18n.t('errors.addListing.discount.between', {
            minChar: this.$n(1, 'currency', 'en-SG'),
            maxChar: this.$n(this.sellPrice, 'currency', 'en-SG'),
          }),
        );
    },
    discountPercentage(value) {
      if (!this.showDiscount) return null;
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.discount.required'))
        .between(
          1,
          99.99,
          this.$i18n.t('errors.addListing.discount.between', {
            minChar: '1%',
            maxChar: '99,99%',
          }),
        );
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
